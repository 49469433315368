import React from 'react';

import {
  OnePostWrapper,
  InfoBar,
  PicName,
  Pic,
  Name,
  ActionArea,
  Tags,
  TagName,
  Date,
  Circle,
} from './shimmer.styles';

export default function ShimmerOnePost() {
  return (
    <OnePostWrapper>
      <InfoBar>
        <PicName>
          <Pic className="shimmer-wrapper-pic" />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Name className="shimmer-wrapper-pic" />
            <Date className="shimmer-wrapper-pic" />
          </div>
        </PicName>
      </InfoBar>
      <div className="text shimmer-wrapper-pic"></div>
      <div className="text shimmer-wrapper-pic"></div>
      <div className="text shimmer-wrapper-pic"></div>

      {/* <MediaArea className="shimmer-wrapper-pic" /> */}

      <Tags>
        <TagName className="shimmer-wrapper-pic" backgroundColor="white" />
        <TagName className="shimmer-wrapper-pic" backgroundColor="white" />
        <TagName className="shimmer-wrapper-pic" backgroundColor="white" />
      </Tags>
      <ActionArea>
        <Circle className="shimmer-wrapper-pic" />
        <Circle className="shimmer-wrapper-pic" />
        <Circle className="shimmer-wrapper-pic" />
        <Circle className="shimmer-wrapper-pic" />
      </ActionArea>
    </OnePostWrapper>
  );
}
