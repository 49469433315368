import React, { useState, useEffect } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import {
  BsHeartFill,
  BsHeart,
  BsBookmark,
  BsBookmarkFill,
} from 'react-icons/bs';
import { FaRegCommentDots } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { IoMdArrowRoundBack } from 'react-icons/io';

import {
  OnePostWrapper,
  InfoBar,
  PicName,
  Pic,
  Name,
  Dots,
  CaptionArea,
  MediaArea,
  ActionArea,
  CommentBtn,
  LikeBtn,
  ShareBtn,
  BookmarkBtn,
  DotsBanner,
  TextOne,
  CommentArea,
  Comment,
  InfoArea,
  PicComment,
  NameComment,
  CommentText,
  NameAndTime,
  TimeComment,
  LikeAndComment,
  BoxArea,
  TextAreaAdd,
  ButtonAdd,
  Tags,
  TagName,
} from './index.styles';

import Image from 'next/image';
import PropTypes from 'prop-types';
import axios from '../../utils/api';
import {
  CenteredSpinner,
  DotsLoading,
} from '@components/reusable/index.spinner';
import { useRouter } from 'next/router';

import { karentCache } from '../../utils/data/actions';

import messageBanner from '@components/reusable/messageBanner';
import SwipeImages from '@components/reusable/gallery';
import ShimmerOnePost from './shimmer';
import { useSession } from 'next-auth/react';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from '@services/baseApiSlice';

export const colorSwitcher = (val) => {
  if (
    val.toLowerCase() == '63e494773b02d762a991783a' ||
    val === 'conversation'
  ) {
    return ['rgba(191, 212, 242, 0.5)', '#f1f1f1'];
  }
  if (
    val.toLowerCase() == '63e494bd3b02d762a991783b' ||
    val === 'entrepreneurship'
  ) {
    return ['rgba(77, 211, 34, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e494db3b02d762a991783c' || val === 'writing') {
    return ['rgba(224, 110, 61, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e494ee3b02d762a991783d' || val === 'tech') {
    return ['rgba(33, 98, 7, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495003b02d762a991783e' || val === 'careers') {
    return ['rgba(48, 166, 166, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495113b02d762a991783f' || val === 'social') {
    return ['rgba(43, 62, 142, 1)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495203b02d762a9917840' || val === 'music') {
    return ['rgba(94, 129, 61, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495303b02d762a9917841' || val === 'travel') {
    return ['rgba(132, 120, 146, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495413b02d762a9917842' || val === 'food') {
    return ['rgba(58, 179, 178, 0.5)', '#f1f1f1'];
  }
  if (val.toLowerCase() == '63e495513b02d762a9917843' || val === 'art') {
    return ['rgba(96, 234, 178, 0.5)', '#f1f1f1'];
  }
};

export const idToNameSwitcher = (val) => {
  if (val.toLowerCase() == '63e494773b02d762a991783a') {
    return 'conversation';
  }
  if (val.toLowerCase() == '63e494bd3b02d762a991783b') {
    return 'entrepreneurship';
  }
  if (val.toLowerCase() == '63e494db3b02d762a991783c') {
    return 'writing';
  }
  if (val.toLowerCase() == '63e494ee3b02d762a991783d') {
    return 'tech';
  }
  if (val.toLowerCase() == '63e495003b02d762a991783e') {
    return 'careers';
  }
  if (val.toLowerCase() == '63e495113b02d762a991783f') {
    return 'social';
  }
  if (val.toLowerCase() == '63e495203b02d762a9917840') {
    return 'music';
  }
  if (val.toLowerCase() == '63e495303b02d762a9917841') {
    return 'travel';
  }
  if (val.toLowerCase() == '63e495413b02d762a9917842') {
    return 'food';
  }
  if (val.toLowerCase() == '63e495513b02d762a9917843') {
    return 'art';
  }
};

export default function OnePost({
  post,
  reRender,
  disableClick,
  modalData,
  showModal,
  fromprofile,
  showShare,
  fromview,
  receivedBookIds,
  nowZero,
  preview,
  user,
}) {
  const router = useRouter();
  const userinfo = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    post && router.prefetch(`/post/${post.id}`);
  }, [post]);

  const userId = post?.userId;
  const { data: userData } = useGetUserQuery(userId, {
    skip: !userId,
  });

  const formatDate = (date) => {
    const dateObj = new Date(date);
    const dateStr = dateObj.toDateString();
    const timeStr = dateObj.toLocaleTimeString();
    return `${dateStr} ${timeStr}`;
  };

  const goToUserProfile = () => {
    if (!disableClick) {
      router.push(`/profile/${userData?.handle}`);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const goToCommentUser = (val) => {
    router.push('/profile');
    localStorage.setItem('activeProfile', val);
  };

  const [userID, setUserID] = useState(null);

  const { data: session } = useSession();

  useEffect(() => {
    localStorage.getItem('userid') && setUserID(localStorage.getItem('userid'));
  }, [post]);

  // likes start

  useEffect(() => {
    if (session) {
      if (!localStorage.getItem(`post_${post?._id}_like`)) {
        setIsLiked(post?.likes.filter((e) => e.userId === userID).length > 0);
      } else {
        setIsLiked(JSON.parse(localStorage.getItem(`post_${post?._id}_like`)));
        setPendingPost(true);
      }

      setInitialLike(post?.likes.filter((e) => e.userId === userID).length > 0);
    }
  }, [post, userID, session]);

  const [isLiked, setIsLiked] = useState(false);
  const [initialLike, setInitialLike] = useState(false);
  const [countLikes, setCountLikes] = useState(post?.likes.length);

  const [pendingPost, setPendingPost] = useState(false);

  const likingAction = () => {
    setIsLiked(!isLiked);
    setCountLikes(isLiked ? countLikes - 1 : countLikes + 1);
    localStorage.setItem(`post_${post?._id}_like`, !isLiked);
    setPendingPost(true);
  };

  // background activity for posting likes
  useEffect(() => {
    const likingMethod = async () => {
      try {
        const data = await axios.post(`/posts/${post?._id}/${userID}/like`);
        setPendingPost(false);
        localStorage.removeItem(`post_${post?._id}_like`);
        // get posts from local storage and update
        const posts = JSON.parse(localStorage.getItem('posts'));
        const newPosts = posts.filter((e) => e._id !== post?._id);
        const updatedPosts = [...newPosts, data.data];
        localStorage.setItem('posts', JSON.stringify(updatedPosts));
      } catch (error) {
        console.log(error);
      }
    };
    // Update debounced value after delay
    const handler = setTimeout(() => {
      pendingPost && initialLike !== isLiked && likingMethod();
    }, 5000);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [isLiked]);

  // likes end

  const [dots, setDots] = useState(false);

  const deletePost = async () => {
    setDots(false);
    try {
      // loop through post images and delete using deleteMedia function
      const data = await axios.delete(`/posts/${post?._id}`);
      // need to set to help refresh feed. Can't use query params. Due to router.back, component can have many different origins
      localStorage.setItem('flush', true);
      if (router.pathname.includes('/post')) {
        router.push('/');
      } else {
        data && reRender();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [textarea, setTextarea] = useState('');
  const onChangeTextarea = (e) => {
    setTextarea(e.target.value);
  };

  const [allComments, setAllComments] = useState(null);
  const [getFreshComments, setGetFreshComments] = useState(false);

  const [commentsCount, setCommentsCount] = useState(0);

  const [commentsProcess, setCommentsProcess] = useState(false);

  const reRenderComments = () => {
    localStorage.removeItem('commentsPost' + post?._id);
    setAllComments(null);
    getAllComments();
    setGetFreshComments(!getFreshComments);
  };
  const [awaitData, setAwaitData] = useState(null);

  const getAllComments = async (bool = false) => {
    const Url = `/comments/post/${post?._id}`;
    const Key = 'commentsPost' + post?._id;
    const dataComments = await karentCache(Key, Url, bool);
    setAwaitData(true);
    setAllComments(dataComments);
    setCommentsCount(dataComments.length);
  };
  useEffect(() => {
    !awaitData && getAllComments();
  }, [post, getFreshComments]);

  const postComment = async () => {
    setCommentsProcess(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await axios.post(`/comments/comment`, {
        text: textarea,
        userId: userID,
        name: userinfo?.firstName + ' ' + userinfo?.lastName,
        avatar: userinfo?.avatar,
        postId: post?._id,
      });
      // reRenderComments();
      getAllComments(true);
      setTextarea('');
      setCommentsProcess(false);
    } catch (error) {
      console.log(error);
      setCommentsProcess(false);
    }
  };

  const handleLikeComment = async (val) => {
    setCommentsProcess(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await axios.post(`/comments/${val}/${userID}/like`);
      reRenderComments();
      setCommentsProcess(false);
    } catch (error) {
      console.log(error);
      setCommentsProcess(false);
    }
  };

  const deleteComment = async (val) => {
    try {
      const data = await axios.delete(`/comments/${val}`);
      data && reRenderComments();
    } catch (error) {
      console.log(error);
    }
  };

  const followLogic = async () => {
    setDots(false);
    try {
      setIsFollowing(true);
      // eslint-disable-next-line no-unused-vars
      const data = axios.post(`/users/${userID}/${userData._id}/follow`);
    } catch (error) {
      console.log(error);
    }
  };

  const [loadingLikers, setLoadingLikers] = useState(false);

  const fetchPostLikers = async (bool) => {
    if (countLikes.length > 0) {
      return;
    }
    setLoadingLikers(true);
    const Url = `/posts/${post?._id}/likes`;
    const Key = 'postLikers' + post?._id;
    const data = await karentCache(Key, Url, bool);
    modalData(data);
    setLoadingLikers(false);
    showModal(true);
  };

  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(`${window.location.origin}/post/${post?._id}`);
  }, [post?._id]);

  const processTime = (time) => {
    const todayTime = new Date().getTime();
    const timeTime = new Date(time).getTime();
    // difference in seconds
    const diff = (todayTime - timeTime) / 1000;
    // difference in minutes
    const diffMinutes = Math.floor(diff / 60);
    // difference in hours
    const diffHours = Math.floor(diff / 3600);
    // difference in days
    const diffDays = Math.floor(diff / 86400);
    // console log all differences
    if (diffDays > 0) {
      return `${diffDays}d ago`;
    }
    if (diffHours > 0) {
      return `${diffHours}h ago`;
    }
    if (diffMinutes > 0) {
      return `${diffMinutes}m ago`;
    }
    return `<1m ago`;
  };

  const [debounceBookmark, setDebounceBookmark] = useState(false);
  const [loadingBookmark, setLoadingBookmark] = useState(false);
  const addToBookMark = async () => {
    setDots(false);
    setLoadingBookmark(true);
    try {
      const data = await axios.post(`/bookmarks/create`, {
        postId: post?._id,
        userId: userID,
      });

      setLoadingBookmark(false);

      data.data.deleted
        ? messageBanner({
            message: `Removed from bookmarks `,
            status: 'info',
          }) && setDebounceBookmark(false)
        : messageBanner({
            message: `Added to bookmarks 😍`,
            status: 'info',
          }) && setDebounceBookmark(true);
      setBookmarkId(data.data);
    } catch (error) {
      setLoadingBookmark(false);
    }
  };

  const [bookmarkId, setBookmarkId] = useState(null);

  const removeBookMark = async () => {
    setDots(false);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await axios.delete(`/bookmarks/${bookmarkId}`);
      messageBanner({
        message: `Removed from bookmarks `,
        status: 'info',
      });
      setDebounceBookmark(false);
      if (router.pathname.includes('/bookmarks')) {
        nowZero(post?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (receivedBookIds && post) {
      const matchingId = receivedBookIds.filter(
        (item) => item.postId === post?._id,
      );
      matchingId.length > 0 && setBookmarkId(matchingId[0]._id);
      setDebounceBookmark(matchingId.length > 0);
    }
  }, [receivedBookIds, post]);

  const [activeImage, setActiveImage] = useState(1);

  const [showDots, setShowDots] = useState(false);

  useEffect(() => {
    fromview && setShowDots(true);
    router.pathname.includes('/bookmarks') &&
      localStorage.getItem('userid') === userData?._id &&
      setShowDots(true);
    localStorage.getItem('userid') === userData?._id &&
      router.pathname.includes('/post') &&
      setShowDots(true);
  }, [post, userData, fromview]);

  const [isFollowing, setIsFollowing] = useState(false);
  useEffect(() => {
    userID && userData && setIsFollowing(userData?.followers?.includes(userID));
  }, [userData, userID]);

  return (
    <>
      {!router.pathname.includes('post') && (!post || !userData) ? (
        <ShimmerOnePost />
      ) : (
        <OnePostWrapper
          className=""
          fromview={fromview}
          fromprofile={fromprofile}
        >
          {fromview && (
            <div
              className="back"
              onClick={() => {
                router.back();
              }}
            >
              <IoMdArrowRoundBack className="icon backicon" />
            </div>
          )}
          <InfoBar>
            {session && (
              <DotsBanner showDots={dots}>
                {session && userID !== post?.userId && (
                  <TextOne onClick={() => followLogic()}>
                    {isFollowing
                      ? `Unfollow @${userData?.handle}`
                      : `Follow @${userData?.handle}`}
                  </TextOne>
                )}
                {userID === post?.userId && (
                  <TextOne onClick={() => deletePost()}>Delete post</TextOne>
                )}
                {userID === post?.userId && (
                  <TextOne className="edit" disabled={true}>
                    Edit post
                  </TextOne>
                )}
                {userID === post?.userId && (
                  <TextOne
                    onClick={() => {
                      debounceBookmark ? removeBookMark() : addToBookMark();
                    }}
                  >
                    {debounceBookmark
                      ? 'Remove from bookmarks'
                      : 'Add to bookmarks'}
                  </TextOne>
                )}
              </DotsBanner>
            )}
            <PicName>
              <Pic
                onClick={(e) => {
                  e.preventDefault();
                  goToUserProfile();
                }}
                src={
                  user
                    ? user?.avatar?.url
                      ? user?.avatar?.url
                      : user?.avatar?.secure_url
                      ? user?.avatar?.secure_url
                      : `https://avatars.navu360.com/api/micah/${post?.userId}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
                    : userData?.avatar?.url
                    ? userData?.avatar?.url
                    : userData?.avatar?.secure_url
                    ? userData?.avatar?.secure_url
                    : `https://avatars.navu360.com/api/micah/${post?.userId}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
                }
              />

              <div className="namedate py-0">
                <Name
                  disableClick={!disableClick}
                  onClick={(e) => {
                    e.preventDefault();
                    goToUserProfile();
                  }}
                >
                  {user
                    ? user?.firstName + ' ' + user?.lastName
                    : userData?.firstName + ' ' + userData?.lastName}
                </Name>
                <p className="date">
                  {router.pathname === '/'
                    ? processTime(post?.createdAt)
                    : formatDate(post?.createdAt)}
                </p>
              </div>
            </PicName>

            {showDots && (
              <Dots onClick={() => setDots(!dots)}>
                <Image
                  src={'/assets/dots.svg'}
                  height={24}
                  width={24}
                  alt="dots"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Dots>
            )}
          </InfoBar>
          <div
            className="text"
            onClick={() => {
              router.pathname.includes('post')
                ? null
                : router.push(`/post/${post?._id}`) &&
                  localStorage.setItem('toView', post);
            }}
          >
            {post?.textContent.split('\n').map((text, index) => (
              <CaptionArea
                dangerouslySetInnerHTML={{ __html: text }}
                key={index}
              ></CaptionArea>
            ))}
          </div>
          {post?.images.length > 0 && (
            <MediaArea
              onClick={() => {
                router.pathname.includes('post')
                  ? null
                  : router.push(`/post/${post?._id}`);
              }}
            >
              <SwipeImages
                images={post?.images}
                openFoodPage={() => {
                  router.pathname.includes('post')
                    ? null
                    : router.push(`/post/${post?._id}`);
                }}
                forAlt={user?.firstName + ' ' + user?.lastName}
                serActiveImage={(val) => setActiveImage(val)}
              />
              {post?.images.length > 1 && (
                <span className="abs">
                  {activeImage + '/' + post?.images.length}
                </span>
              )}
            </MediaArea>
          )}
          <Tags>
            {post?.tags?.length > 0 &&
              post?.tags?.map((tag, index) => (
                <TagName
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    router.push(
                      `/explore/${
                        tag?.length > 20 ? idToNameSwitcher(tag) : tag
                      }`,
                    );
                  }}
                  backgroundColor={colorSwitcher(tag)[0]}
                  color={colorSwitcher(tag)[1]}
                >
                  {tag?.length > 20 ? idToNameSwitcher(tag) : tag}
                </TagName>
              ))}
          </Tags>
          <ActionArea className="mt-4" onClick={(e) => e.preventDefault()}>
            <CommentBtn
              onClick={() => {
                fromview ? null : router.push(`/post/${post?._id}`);
              }}
            >
              <FaRegCommentDots
                color="#f1f1f1"
                className="icon"
                fontSize="1.5em"
              />
              <p className="action">{commentsCount}</p>
            </CommentBtn>
            <LikeBtn>
              {isLiked ? (
                <BsHeartFill
                  className="icon"
                  color="#f40101"
                  fontSize="1.5em"
                  onClick={() => {
                    !localStorage.getItem('userid')
                      ? preview()
                      : likingAction();
                  }}
                />
              ) : (
                <BsHeart
                  className="icon"
                  fontSize="1.5em"
                  onClick={() => {
                    !localStorage.getItem('userid')
                      ? preview()
                      : likingAction();
                  }}
                />
              )}

              <p
                className="action"
                onClick={() => {
                  countLikes > 0 ? fetchPostLikers(false) : null;
                }}
              >
                {loadingLikers ? '⌛' : countLikes}
              </p>
            </LikeBtn>
            <ShareBtn onClick={() => showShare(`${baseUrl}`, userData?.handle)}>
              <FiShare2
                className="icon mr-1"
                color="#f1f1f1"
                fontSize="1.5em"
              />
            </ShareBtn>
            <BookmarkBtn
              onClick={() => {
                !localStorage.getItem('userid') ? preview() : addToBookMark();
              }}
            >
              {loadingBookmark ? (
                <DotsLoading />
              ) : !debounceBookmark ? (
                <BsBookmark className="icon" color="#f1f1f1" fontSize="1.5em" />
              ) : (
                <BsBookmarkFill
                  className="icon"
                  color="#4fd8e0"
                  fontSize="1em"
                />
              )}
            </BookmarkBtn>
          </ActionArea>

          {fromview && (
            <CommentArea
              fromview={fromview}
              margintop={!allComments}
              className="mt-4"
            >
              {!allComments ? (
                <CenteredSpinner text={'Getting comments '} />
              ) : (
                <div className="comments">
                  {session && (
                    <BoxArea>
                      <TextAreaAdd
                        type="textarea"
                        name="comment"
                        value={textarea}
                        onChange={(e) => onChangeTextarea(e)}
                        required
                        maxLength={100}
                        placeholder="Add a comment"
                      />

                      <ButtonAdd
                        disabled={textarea.length < 1 || commentsProcess}
                        onClick={() => postComment()}
                      >
                        {commentsProcess ? 'Creating...' : 'Comment'}
                      </ButtonAdd>
                    </BoxArea>
                  )}

                  {allComments.length === 0 ? (
                    <h4>No comments yet</h4>
                  ) : (
                    allComments?.map((comment) => (
                      <Comment role="comment" key={comment?._id}>
                        <InfoArea>
                          <PicComment
                            src={
                              comment?.avatar?.url
                                ? comment?.avatar?.url
                                : comment?.avatar?.secure_url
                                ? comment?.avatar?.secure_url
                                : `https://avatars.navu360.com/api/micah/${comment?.userId}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
                            }
                          />
                          <NameAndTime>
                            <NameComment
                              onClick={() => goToCommentUser(comment?.userId)}
                            >
                              {comment?.name}
                              <div className="underline" />
                            </NameComment>

                            <TimeComment>
                              {formatDate(comment?.createdAt)}
                            </TimeComment>
                          </NameAndTime>
                        </InfoArea>
                        <LikeAndComment>
                          <CommentText>{comment?.text}</CommentText>
                          <LikeBtn
                            className="commentlikee all"
                            onClick={() => handleLikeComment(comment?._id)}
                          >
                            {comment?.likes.some((e) => e.userId === userID) ? (
                              <Image
                                src={'/assets/likered.svg'}
                                height={20}
                                width={20}
                                alt="comment"
                                style={{
                                  maxWidth: '100%',
                                  height: 'auto',
                                }}
                              />
                            ) : (
                              <Image
                                src={'/assets/likewhite.svg'}
                                height={20}
                                width={20}
                                alt="comment"
                                style={{
                                  maxWidth: '100%',
                                  height: 'auto',
                                }}
                              />
                            )}

                            <p className="action space commentlike">
                              {comment?.likes?.length || 0}
                            </p>
                          </LikeBtn>
                          {comment?.userId === userID && (
                            <AiOutlineDelete
                              onClick={() => deleteComment(comment?._id)}
                              className="icon delete"
                              fontSize="1.5em"
                            />
                          )}
                        </LikeAndComment>
                      </Comment>
                    ))
                  )}
                </div>
              )}
            </CommentArea>
          )}
        </OnePostWrapper>
      )}
    </>
  );
}

// proptypes
OnePost.propTypes = {
  post: PropTypes.object.isRequired,
  userinfo: PropTypes.object,
  reRender: PropTypes.func,
  disableClick: PropTypes.bool,
  modalData: PropTypes.func,
  showModal: PropTypes.func,
  fromprofile: PropTypes.bool,
  showShare: PropTypes.func,
  fromview: PropTypes.bool,
  fromhome: PropTypes.bool,
  fromsearch: PropTypes.bool,
  frombookmarks: PropTypes.bool,
  fromuser: PropTypes.bool,
  fromuserprofile: PropTypes.bool,
  receivedBookIds: PropTypes.array,
  nowZero: PropTypes.func,
  preview: PropTypes.func,
  user: PropTypes.object,
};
