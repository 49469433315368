import React, { useEffect, useState } from 'react';
import axios from '../../utils/api';
import { useRouter } from 'next/router';
import { MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';
import {
  OneUser,
  ProfilePic,
  Names,
  TheName,
  Thehandle,
  FollowButton,
} from './index.styles';

export default function OneUserC({ user }) {
  const router = useRouter();
  const [userId, setUserId] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  useEffect(() => {
    userId && user && setIsFollowing(user.followers.includes(userId));
  }, [user]);

  const followLogic = async (val) => {
    if (userId) {
      try {
        setIsFollowing(!isFollowing);
        // eslint-disable-next-line no-unused-vars
        const data = await axios.post(`/users/${userId}/${val}/follow`);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('login');
    }
  };

  const goToUserProfile = (val) => {
    router.push(`/profile/${val}`);
  };

  const ButtonLogic = (val) => {
    if (val?._id === userId) {
      router.push(`/profile/${val?.handle}`);
    } else {
      followLogic(val?._id);
    }
  };

  useEffect(() => {
    localStorage.getItem('userid') && setUserId(localStorage.getItem('userid'));
  }, []);
  return (
    <OneUser>
      <ProfilePic
        onClick={() => goToUserProfile(user?.handle)}
        src={
          user?.avatar?.url
            ? user?.avatar?.url
            : user?.avatar?.secure_url
            ? user?.avatar?.secure_url
            : `https://avatars.navu360.com/api/micah/${user?._id}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
        }
      />
      <div className="between">
        <Names onClick={() => goToUserProfile(user?.handle)}>
          <TheName>{user?.firstName + ' ' + user?.lastName}</TheName>
          <Thehandle>@{user?.handle}</Thehandle>
        </Names>
        {user?.bio ? (
          <p className="biodata bioinfo">{user?.bio}</p>
        ) : user._id === localStorage.getItem('userid') ? (
          <p className="instruct biodata">
            Add bio in settings{' '}
            <MdEdit
              onClick={() => router.push('/settings')}
              className="icon"
              role="link"
            />
          </p>
        ) : (
          <p className="biodata">Socializing is the way 😜</p>
        )}
        <FollowButton onClick={() => ButtonLogic(user)}>
          {user._id === userId
            ? 'My profile'
            : isFollowing
            ? 'Following'
            : 'Follow'}
        </FollowButton>
      </div>
    </OneUser>
  );
}

OneUserC.propTypes = {
  user: PropTypes.object,
};
