import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 30vh;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  /* hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;
  /* scroll snapping */
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
  /* .nav {
    display: none;
  } */
  @media screen and (min-width: 900px) {
    height: 50vh;
  }
`;

export const Wrap = styled.div`
  position: relative;
  width: 100%;

  &:hover .nav {
    opacity: 1;
  }
  .nav {
    display: block;
    position: absolute;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: #f1f1f1;
    border-radius: 50%;
    padding: 5px;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    top: 50%;
    transform: translateY(-60%);
    bottom: 0;
    cursor: pointer;
    /* transform: translateY(50%); */
    .icons {
      color: #f1f1f1 !important;
      font-size: 1.5rem;
    }
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  @media screen and (max-width: 900px) {
    .nav {
      display: none;
    }
  }
`;

export const SwipeImage = styled.img`
  width: 50vw;
  min-width: 50vw;
  /* border-radius: 10px; */

  min-height: 100%;
  max-height: 100%;

  object-fit: cover;

  /* scroll snapping */
  scroll-snap-align: start;

  scroll-snap-stop: always;
  transition: all 0.3s ease-out;

  @media screen and (max-width: 900px) {
    width: 100%;
    min-width: 100%;
  }
`;
