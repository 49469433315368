import styled from 'styled-components';

export const SearchContainer = styled.div`
  width: auto;
  height: 100vh;

  .info {
    width: 100%;
    text-align: center;
    color: #f1f1f1;
  }
  @media screen and (max-width: 900px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    margin: 0;
  }
`;
export const OptionsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 3px solid rgba(0, 0, 0, 0.1); */
  /* width: 100%; */
  padding: 0.5rem 1rem;
  border-radius: 5px;
  @media screen and (max-width: 900px) {
    width: 100vw;
    margin: 0;
  }
`;
export const Option = styled.div`
  font-weight: bold;
  margin-right: 1.5rem;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  background-color: ${(props) =>
    props.isActive ? 'rgba(42, 163, 239, 0.2)' : 'transparent'};
`;
export const TextOption = styled.h2`
  padding: 0;
  margin: 0;
  color: ${(props) => (props.isActive ? '#f1f1f1' : '#f1f1f1')};
`;
export const UnderLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => (props.isActive ? '#f1f1f1' : '#f1f1f1')};
  padding: 0;
  margin: 0;
`;

export const AllResults = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
  }
`;

export const OneUser = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(42, 163, 239, 0.04);
  border-radius: 30px;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  /* justify-content: space-between; */
  padding: 0.5rem;
  .between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .instruct {
      display: flex;
      align-items: center;
      font-style: italic;
      color: #f1f1f1;
      .icon {
        margin-left: 5px;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .biodata {
      text-align: center;
      color: #f1f1f1;
      font-size: 1vw;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .bioinfo {
      font-weight: 500;
      color: #f1f1f1;
    }
  }
`;
export const ProfilePic = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
`;
export const Names = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f1f1f1;
  cursor: pointer;
`;
export const TheName = styled.p`
  font-weight: 500;
  padding: 0;
  margin: 0;
`;
export const Thehandle = styled.p`
  font-style: italic;
  padding: 0;
  margin: 0;
`;
export const FollowButton = styled.button`
  /* width: 120px; */
  /* height: 30px; */
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  background-color: #f1f1f1;
  color: #000;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`;
