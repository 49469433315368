import axios from '../../utils/api';

// caching
export async function karentCache(key, url, shouldGetFresh, prev = null) {
  const cachedData = localStorage.getItem(key);
  // check elapsed time since last saved
  const lastSaved = Date.parse(localStorage.getItem(`${key}_date`));
  const today = new Date();
  // get difference in milliseconds
  const elapsed = today - lastSaved;
  let toreturn = null;

  if (cachedData && !shouldGetFresh && elapsed < 60000) {
    toreturn = JSON.parse(cachedData);
  } else {
    try {
      const response = await axios.get(url);
      const toSend = prev
        ? [...prev, ...response.data.posts]
        : response.data.posts;
      localStorage.setItem(
        key,
        JSON.stringify(key === 'posts' ? toSend : response.data),
      );
      // saved to localstorage on date
      const now = new Date();
      localStorage.setItem(`${key}_date`, now);

      toreturn =
        key === 'posts'
          ? [toSend, response.data.totalPages, 'done']
          : response.data;
    } catch (error) {
      console.log(error);
      toreturn = [];
    }
  }
  return toreturn;
}
