import styled, { keyframes, css } from 'styled-components';

export const Spinner = keyframes`
to {transform: rotate(360deg);}
`;

export const FeedMain = styled.main`
  width: 100%;
  min-width: 100%;
  // margin: auto;
  height: 100vh;
  position: relative;

  a {
    color: #f1f1f1;
    text-decoration: none;
  }

  .status {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  @media screen and (max-width: 900px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: auto;
    margin-bottom: 15vh;
  }

  ${(props) =>
    props?.noPosts &&
    css`
      min-height: 70vh;
    `}
`;

export const OnePostWrapper = styled.div`
  width: auto;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  margin: auto;
  background: rgba(42, 163, 239, 0.05);
  border-radius: 5px;
  margin-bottom: 1rem;
  color: #f1f1f1;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: 40vw;
  }
  @media screen and (max-width: 1080px) {
    width: 40vw;
  }
  @media screen and (max-width: 1024px) {
    width: 40vw;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    min-width: 100%;
    position: relative;
    /* margin-left: 1rem; */
  }
  .text {
    padding: 0.8rem;
  }
  .icon {
    transition: all 0.3s linear;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .smooth {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-radius: 50%;
  }
  .spinner.smooth::after {
    border-top: 4px solid rgba(255, 255, 255, 1);
    border-left: 4px solid rgba(255, 255, 255, 1);
    border-right: 4px solid rgba(255, 255, 255, 0);
    animation: ${Spinner} 0.6s linear infinite;
  }
  ${(props) =>
    props?.fromview &&
    css`
      /* margin-top: -4rem; */
      padding-top: 4rem;
      width: auto;
      height: auto;
      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
      }
    `}
  .back {
    position: fixed;
    top: 5rem;
    left: 23vw;
    right: 27.6vw;
    background: rgb(25, 69, 71);
    background: radial-gradient(
      circle,
      rgba(25, 69, 71, 1) 7%,
      rgba(21, 32, 43, 1) 74%
    );
    height: 50px;
    z-index: 10;
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    @media screen and (max-width: 900px) {
      left: 0;
      right: 0;
    }
  }
  .backicon {
    font-size: 2rem;
    margin-left: 1.5rem;
    &:hover {
      color: #4fd8e0;
    }
  }
`;

export const InfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
export const PicName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  p {
    padding: 0;
    margin: 0;
  }
  .date {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0rem;
  }
  @media screen and (max-width: 900px) {
    .date {
      font-size: 2vw;
      font-size: 10px;
      font-size: 0.6em;
    }
  }
  .namedate {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* outline: thin solid green; */
    /* position: relative; */
  }
`;
export const Pic = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`;
export const Name = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  margin-top: -0.7rem;

  ${(props) =>
    props?.disableClick &&
    css`
      &:hover .underline {
        width: 100%;
      }
    `}
  @media screen and (max-width: 900px) {
    font-size: 3.8vw;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
`;
export const Dots = styled.div`
  cursor: pointer;
`;

export const CaptionArea = styled.p`
  margin: 0;
  min-height: 1.5rem;
  color: #f1f1f1;

  span {
    color: #4fd8e0;
    text-decoration: underline;
    cursor: pointer;
  }
  .hashtag {
    cursor: pointer;
  }
  a {
    color: #4fd8e0;
  }
`;

export const MediaArea = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 0.6rem;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    height: auto;
    gap: 1rem;
  }
  .abs {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    background: #15202b;
    color: #f1f1f1;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50%;
  }
`;
export const TwoDown = styled.div`
  height: 20vh;
  width: 20vw;
  display: flex;
  flex-direction: row;
`;
export const OneMedia = styled.div`
  background-image: url(${(props) => props.src});
  background-size: 100% auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
`;

export const MediaPreview = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(20px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  .relative {
    position: relative;
    height: 70vh;
    width: 90vh;
  }

  @media screen and (max-width: 900px) {
    /* position: absolute; */
    height: 100%;
    border-radius: 30px;
  }

  .image {
    /* object-fit: contain; */
    /* width: 100%; */
    position: relative;
    /* height: 50%; */
  }
  .icon {
    font-size: 2em;
    transition: all 0.3s ease;
    position: absolute;
    cursor: pointer;
    font-size: 3em;
    z-index: 11;
    &:hover {
      color: #4fd8e0;
    }
  }
  .close {
    top: 6rem;
    right: 2%;
  }
  @media screen and (max-width: 900px) {
    font-size: 0.7rem;
    .close {
      top: 6rem;
      /* top: 2%; */
    }
  }
  .prev,
  .next {
    top: 50%;
  }
  .prev {
    left: 5%;
  }
  .next {
    right: 5%;
  }
  @media screen and (max-width: 900px) {
    font-size: 0.7rem;
    .close {
      top: 6rem;
      /* top: 2%; */
    }
    .prev {
      left: 2%;
    }
    .next {
      right: 2%;
    }
  }
  .profile {
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 15;
    color: #f1f1f1;
    font-size: 2rem;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 15;
    &:hover {
      color: #4fd8e0;
    }

    @media screen and (max-width: 900px) {
      right: 7vw;
      top: 20vh;
    }
  }
`;

export const Animation = keyframes`
0% {
  opacity: 0.1;
}
100% {
  opacity: 1;
}
`;

export const ShowP = keyframes`
0% {
  transform: translateY(5px);
}
100% {
  transform: translateY(0px);
}`;

export const ShowIcon = keyframes`
0% {
  transform: scale(0.8);
}
100% {
  transform: scale(1);
}`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  .action {
    // padding-left: 0.5rem;
    font-weight: 500;
  }
  .icon {
    animation: ${ShowIcon} 0.3s ease;
  }
`;
export const CommentBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  p {
    margin-left: 0.5rem;
    animation: ${ShowP} 1s ease;
  }
`;
export const LikeBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    cursor: pointer;
    &:hover {
      stroke: #f40101;
    }
  }
  p {
    margin-left: 0.5rem;
    cursor: pointer;
    animation: ${ShowP} 0.5s linear;
  }
`;
export const ShareBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const BookmarkBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const DotsBanner = styled.div`
  position: absolute;
  right: 0%;
  top: 50%;
  width: max-content;

  animation: ${Animation} 0.5s linear;

  visibility: ${(props) => (props.showDots ? 'visible' : 'hidden')};

  background-color: #f1f1f1;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  z-index: 3;
  .edit {
    cursor: not-allowed;
  }

  @media screen and (max-width: 900px) {
    width: max-content;
  }
`;
export const TextOne = styled.p`
  padding: 0;
  margin: 0;
  color: #000;
  width: max-content;
  min-width: 80%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #bdbdbd;
  }
`;

export const CommentsShow = keyframes`
0%{
  transform: translateY(10vh);
}
100% {
  transform: translateY(0);
}
`;

export const CommentsClose = keyframes`
0%{
  transform: translateY(0);
  opacity: 1;
}
100% {
  transform: translateY(-20vh);
  z-index: -1;
  opacity: 0;
}
`;

export const CommentArea = styled.div`
  // outline: thin solid rgba(25, 69, 71, 1);
  border-radius: 10px;
  padding: 0.8rem;
  width: 100%;
  /* z-index: -1; */

  // box-shadow: 0px -0.5px 5px -2px rgba(255, 255, 255, 0.82);

  ${(props) =>
    props?.beenChanged
      ? css`
          animation: ${CommentsClose} 0.5s linear;
        `
      : css`
          animation: ${CommentsShow} 0.5s linear;
        `}
  position: relative;

  display: flex;
  flex-direction: column;

  ${(props) =>
    props?.margintop &&
    css`
      height: 30vh;
    `}
  /* align-items: flex-end; */

  .comments {
    height: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-left: 0.5rem;
    ${(props) =>
      props?.fromview &&
      css`
        max-height: auto;
        height: auto;
        overflow-y: unset;
      `}
  }
  .commentlikee {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  h4 {
    width: 100%;
    text-align: center;
  }
`;
export const Comment = styled.div`
  border-bottom: thin solid rgba(25, 69, 71, 1);
  margin-bottom: 1rem;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PicComment = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 1rem;
`;
export const NameAndTime = styled.div``;
export const NameComment = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  cursor: pointer;
  &:hover .underline {
    width: 100%;
  }
`;
export const TimeComment = styled.p`
  margin: 0;
  padding: 0;
  font-size: 10px;
`;
export const LikeAndComment = styled.div`
  display: flex;
  max-width: 98%;
  flex-direction: row;
  align-items: center;

  .commentlike {
    margin-left: 0.25rem;
  }
  .delete {
    margin-left: 0.5rem;
  }
`;
export const CommentText = styled.p`
  margin: 0;
  padding: 0;
  width: 85%;

  margin-right: 0.5rem;
`;
export const ActionComment = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
`;

export const AddComment = styled.div`
  /* height: 40px; */
`;

export const BoxArea = styled.div`
  width: 90%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 0.5rem; */
  margin-top: 0.2rem;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const TextAreaAdd = styled.textarea`
  width: 70%;
  margin-right: 1rem;
  border: 0;
  border-radius: 10px;
  height: 60px;
  font-weight: 500;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  color: #ffffff;
  background: rgba(42, 163, 239, 0.06);
  transition: all 0.3s linear;
  &::placeholder {
    opacity: 0.7;
  }
  &:focus {
    height: 100px;
  }
  resize: none;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    width: 100%;
    margin-right: 0;
  }
`;
export const ButtonAdd = styled.button`
  background-color: #f1f1f1;
  color: #191919;
  // height: 40px;
  padding: 0.5rem 1.5rem;
  width: max-content;
  border-radius: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const LimitText = styled.p`
  position: absolute;
  right: 30%;
  bottom: -3rem;
  color: #4fd8e0;
  opacity: 0.5;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    right: 0%;
    top: 0;
    /* margin-left: 0.5rem; */
  }
`;

export const Tags = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.8rem;
  padding-bottom: 0.5rem;
`;

export const Tag = styled.span`
  font-size: 10px;
  padding: 0.1rem 0.5rem;
  border-radius: 25px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid white;
  color: white;
  background: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
  }
`;

export const OneTag = styled.label`
  font-weight: 500;
  font-size: 22px;
`;

export const TagName = styled.span`
  font-size: 12px;
  letter-spacing: 0.1em;
  padding: 0.25rem 0.5rem;
  border-radius: 25px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  font-weight: 500;
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
  }
`;
