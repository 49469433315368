import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SwipeImage, Wrap } from './gallery.styles';
export default function SwipeImages({ images, openFoodPage, forAlt }) {
  return (
    <Wrap>
      <Wrapper onClick={() => openFoodPage()}>
        {images.map((image, index) => (
          <SwipeImage src={image.url} key={index} loading="lazy" alt={forAlt} />
        ))}
      </Wrapper>
    </Wrap>
  );
}

SwipeImages.propTypes = {
  images: PropTypes.array.isRequired,
  serActiveImage: PropTypes.func,
  openFoodPage: PropTypes.func,
  forAlt: PropTypes.string,
  fromCreator: PropTypes.string,
};
