import React, { useEffect, useState } from 'react';
import {
  WrapUsers,
  InnerModal,
} from './index.styles';

import PropTypes from 'prop-types';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import OneUserC from '@components/search/OneUser';

export default function ListUsers({ listOfPeople, close, text }) {
  const [people, setPeople] = useState(null);

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };
  useEffect(() => {
    setPeople(listOfPeople);
  }, [listOfPeople]);

  return (
    <WrapUsers onClick={(e) => closeModal(e)}>
      <InnerModal>
        <AiOutlineCloseCircle className="close" onClick={() => close()} />
        <h2>{text}</h2>
        {people?.map((user) => (
          <OneUserC key={user?._id} user={user} />
        ))}
      </InnerModal>
    </WrapUsers>
  );
}

ListUsers.propTypes = {
  listOfPeople: PropTypes.array,
  close: PropTypes.func,
  rerender: PropTypes.func,
  text: PropTypes.string,
};
