import styled, { keyframes } from 'styled-components';

export const Animation = keyframes`
0% {
      background-position: -1000px 0;
    }

    100% {
      background-position: 1000px 0;
    }
`;

export const OnePostWrapper = styled.div`
  width: auto;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  margin: auto;
  background: rgba(42, 163, 239, 0.05);
  border-radius: 5px;
  margin-bottom: 1rem;
  color: #f1f1f1;
  z-index: 2;
  position: relative;
  .text {
    height: 8px;
    width: 40vw;
    // background-color: #f1f1f1;
    margin-bottom: 5px;
    // background: rgba(42, 163, 239, 0.5);
    border-radius: 5px;
    animation: ${Animation} 2s infinite linear;
  }

  .shimmer-wrapper-pic {
    background: linear-gradient(
      to right,
      #2aa3ef1a 4%,
      #2aa3ef0d,
      #2aa3ef1a 76%
    );
  }
  .icon {
    transition: all 0.3s linear;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .smooth {
    width: 100%;
    height: 100px;
    position: relative;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const InfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
export const PicName = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  p {
    padding: 0;
    margin: 0;
  }
  .date {
    font-size: 0.8rem;
    font-weight: 500;
  }
  @media screen and (max-width: 900px) {
    .date {
      font-size: 2vw;
      font-size: 10px;
      font-size: 0.6em;
    }
  }
  .namedate {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* outline: thin solid green; */
    /* position: relative; */
  }
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 30s infinite linear;
`;

export const Pic = styled.div`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 15s infinite linear;
  @media screen and (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`;
export const Name = styled.div`
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 12s infinite linear;
  border-radius: 5px;
  height: 20px;
  width: 30vw;

  @media screen and (max-width: 900px) {
    font-size: 3.8vw;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
`;

export const Date = styled.div`
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 10s infinite linear;
  border-radius: 5px;
  height: 10px;
  width: 10vw;

  @media screen and (max-width: 900px) {
    font-size: 3.8vw;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
`;

export const MediaArea = styled.div`
  height: 200px;
  width: 80%;
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 10s infinite linear;
  border-radius: 30px;
`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  .action {
    font-weight: 500;
  }
`;
export const CommentBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  p {
    margin-left: 0.5rem;
  }
`;
export const LikeBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    cursor: pointer;
  }
  p {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;
export const ShareBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const BookmarkBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Tags = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const Tag = styled.span`
  font-size: 10px;
  padding: 0.1rem 0.5rem;
  border-radius: 25px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid white;
  color: white;
  background: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
  }
`;

export const OneTag = styled.label`
  font-weight: 500;
  font-size: 22px;
`;

export const TagName = styled.span`
  width: 100px;
  height: 30px;
  border-radius: 25px;
  background-color: #f1f1f1;
  background: rgba(42, 163, 239, 0.5);
  animation: ${Animation} 10s infinite linear;
`;
