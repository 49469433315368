import styled, { css } from 'styled-components';

export const UserProfileContainer = styled.div`
  width: 100%;
  position: relative;
  @media screen and (max-width: 900px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const ProfileArea = styled.div`
  width: 100%;
  color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  @media screen and (max-width: 900px) {
    width: 90vw;
  }
`;
export const PicsArea = styled.div``;
export const BannerPic = styled.div`
  width: 100%;
  height: 20vh;
  /* background: url(${(props) => props.src}); */
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
`;
export const ProfilePic = styled.div`
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
`;
export const UserData = styled.div`
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .names {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    .instruct {
      display: flex;
      align-items: center;
      font-style: italic;
      .icon {
        margin-left: 5px;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .biodata {
      text-align: center;
      font-weight: 600;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .bioinfo {
      font-weight: 600;
      font-style: italic;
    }
  }
`;
export const EditProfile = styled.button`
  position: absolute;
  right: 0;
  top: 21vh;

  border-radius: 30px;
  /* background: #4fd8e0; */
  color: ${(props) => (props.isFollowing ? '#f1f1f1' : '#000')};
  background: ${(props) => (props.isFollowing ? 'none' : '#f1f1f1')};
  border: ${(props) => (props.isFollowing ? '2px solid #000' : '0')};

  /* width: 130px; */
  width: max-content;
  padding: 0.5rem 1rem;
  /* height: 46px; */

  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-right: 10px;

  @media screen and (max-width: 900px) {
    /* width: 20vw; */
    /* height: 36px; */
    font-size: 14px;
    right: 5%;
  }
`;

export const Follow = styled(EditProfile)``;
export const Name = styled.p`
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 5px;
  text-align: center;
`;
export const UserName = styled.p`
  padding: 0;
  margin: 0;
  margin-top: -1rem;
  font-size: 1rem;
  text-align: center;
`;
export const NumPosts = styled.p`
  padding: 0;
  margin: 0;
`;
export const Statistics = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .num {
    font-weight: 700;
    color: #4fd8e0;
  }
`;
export const Followers = styled.p`
  margin-right: 10px;
  cursor: pointer;
`;
export const Following = styled.p`
  margin-right: 10px;
  cursor: pointer;
`;
export const Posts = styled.div`
  padding-top: 2rem;
  position: relative;

  ${(props) =>
    props?.noPosts &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20vh;
      width: 100%;
    `}

  h3 {
    color: #f1f1f1;
    position: absolute;

    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    h3 {
      top: 0;
      width: 70vw;
    }
  }
`;

export const OneLiner = styled.div`
  position: fixed;
`;
export const PicLeft = styled.div``;
export const InfoRight = styled.div``;

export const WrapUsers = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 500;
  background: rgba(0, 0, 0, 0.8);
  .close {
    display: none;
  }

  @media screen and (max-width: 900px) {
    .close {
      display: block;
      position: fixed;
      right: 0.5vh;
      top: 20vh;
      color: #f1f1f1;
      font-size: 2rem;
      cursor: pointer;
    }
  }
`;
export const InnerModal = styled.div`
  position: relative;
  background: rgb(25, 69, 71);
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 7%,
    rgba(21, 32, 43, 1) 74%
  );
  border-radius: 10px;
  padding: 1rem;
  width: 60%;
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: auto;

  @media screen and (max-width: 900px) {
    max-height: 50vh;
    width: 80%;
    margin: unset;
    margin-left: 0.5rem;
    border-radius: 10px;
    background: rgb(25, 69, 71);

    z-index: 100;
  }
`;
export const OneUser = styled.div`
  display: flex;
  flex-direction: row;
  /* background: rgba(42, 163, 239, 0.04); */
  background-color: rgb(25, 69, 71);
  border-radius: 30px;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  /* justify-content: space-between; */
  padding: 0.5rem;
  .between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .instruct {
      display: flex;
      align-items: center;
      font-style: italic;
      .icon {
        margin-left: 5px;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .biodata {
      text-align: right;
      font-weight: 400;
      position: absolute;
      right: 10vw;
      left: 10vw;
      color: #f1f1f1;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .bioinfo {
      font-weight: 400;
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      /* height: 20vh; */
      .biodata {
        position: static;
        text-align: left;
        padding: 0;
        margin: 0;
        font-weight: 2vh;
        font-size: 4vw;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }
`;
export const ProfilePicc = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    align-self: flex-start;
  }
`;
export const Names = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f1f1f1;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;
export const TheName = styled.p`
  font-weight: 500;
  padding: 0;
  margin: 0;
`;
export const Thehandle = styled.p`
  font-style: italic;
  padding: 0;
  margin: 0;
`;
export const FollowButton = styled.button`
  /* width: 120px; */
  /* height: 30px; */
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  background-color: #f1f1f1;
  color: #000;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    background-color: #404040;
    color: #f1f1f1;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  .title {
    font-weight: 500;
    color: #f1f1f1;
  }
  .tags {
    display: flex;
    gap: 0.5rem;
  }
`;
export const Tag = styled.span`
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
  color: #000;
  padding: 0.25rem 1rem;
  border-radius: 10px;
`;
